<template>
  <div class="fill-height">
    <v-dialog width="600" height="400" v-model="connectionEndedDialog">
      <v-card>
        <v-card-title>Disconnected from session!</v-card-title>
        <v-card-text>{{disconnectMessage}}
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog width="600" v-model="clipboardDialog">
      <v-card>
        <v-card-title class="headline">Clipboard</v-card-title>
        <v-card-text>
        <v-textarea v-model="clipboardContent" @change="sendClipboard" label="Device clipboard">
        </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary darken-1"
              text
              @click="clearClipboard"
          >
            Clear
          </v-btn>
          <v-btn
              color="red darken-1"
              text
              @click="clipboardDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="600" height="400" v-model="calibrationDialog">
      <v-card>

        <v-card-title class="headline red--text">
          WARNING!
        </v-card-title>
        <v-card-text>Calibrating your touchscreen displays using Remote Desktop access is <span
            class="font-weight-bold">not possible!</span>
          You can start the process from here, but touchscreen calibration requires on-site input. <br> Are you sure you
          want to continue?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary darken-1"
              text
              @click="showCalibration"
          >
            Yes
          </v-btn>
          <v-btn
              color="red darken-1"
              text
              @click="calibrationDialog = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="600" height="400" v-model="showMobileWarning">
      <v-card>

        <v-card-title class="headline red--text">
          WARNING!
        </v-card-title>
        <v-card-text>Using NSoft Remote services using a mobile phone is <span
            class="font-weight-bold">not recommended! </span>Consider switching to desktop.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="red darken-1"
              text
              @click="showMobileWarning = false"
          >
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-app-bar fixed dense max-height="48px" color="rgb(40,40,40)" dark :class="{'overflow-x-auto': isMobile()}">
      <v-img src="../assets/logo_white.png" max-height="32px" max-width="105px"></v-img>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="!hideViewDrag">
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="toggleScroll" icon v-bind="attrs" v-on="on" :color="viewDrag ? 'primary' : ''">
            <v-icon>mdi-mouse-move-vertical</v-icon>
          </v-btn>
        </template>
        <span>{{ viewDrag ? 'Disable Scroll mode' : 'Enable Scroll mode'}}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn active-class="btn-active" @click="showKeyboard = !showKeyboard" icon v-bind="attrs" v-on="on"
                 :color="showKeyboard ? 'primary' : ''">
            <v-icon>mdi-keyboard</v-icon>
          </v-btn>
        </template>
        <span>Show extra keys</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="openClipboard" icon v-bind="attrs" v-on="on">
            <v-icon>mdi-clipboard</v-icon>
          </v-btn>
        </template>
        <span>Show clipboard</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="openFullScreen" icon v-bind="attrs" v-on="on" :color="fullscreen ? 'primary' : ''">
            <v-icon>{{ fullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}</v-icon>
          </v-btn>
        </template>
        <span>{{ fullscreen ? 'Exit fullscreen' : 'Go to fullscreen' }}</span>
      </v-tooltip>
<!--      <v-tooltip bottom>-->
<!--        <template v-slot:activator="{ on, attrs }">-->
<!--          <v-btn disabled icon v-bind="attrs" v-on="on">-->
<!--            <v-icon>mdi-cog</v-icon>-->
<!--          </v-btn>-->
<!--        </template>-->
<!--        <span>Settings</span>-->
<!--      </v-tooltip>-->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="showDeviceIP" icon v-bind="attrs" v-on="on">
            <v-icon>mdi-ip</v-icon>
          </v-btn>
        </template>
        <span>Show IP Address</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="calibrationDialog = true" icon v-bind="attrs" v-on="on">
            <v-icon>mdi-target</v-icon>
          </v-btn>
        </template>
        <span>Calibrate displays</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="openSystemConfigurator" icon v-bind="attrs" v-on="on">
            <v-icon>mdi-application-cog</v-icon>
          </v-btn>
        </template>
        <span>Open system configuration tool</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="disconnectSession" icon v-bind="attrs" v-on="on">
            <v-icon>mdi-lan-disconnect</v-icon>
          </v-btn>
        </template>
        <span>Disconnect from VNC</span>
      </v-tooltip>
       <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="turnCursorOn" icon v-bind="attrs" v-on="on">
            <v-icon>mdi-cursor-default</v-icon>
          </v-btn>
        </template>
        <span>Turn on cursor</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{ this.currentConnectionTime }}/{{ connTimeFormatted }}</v-toolbar-title>
    </v-app-bar>
    <v-container fluid class="container fill-height" v-bind:style= "[this.returnRatio > 2 ? {'width':'fit-content','overflow-y': 'auto' }:{}]">
      <div id="screen" v-bind:class="{'full': viewDrag}" ref="screen" v-bind:style= "[this.returnRatio > 2 ? {'max-height': 'calc(100vh - 90px) !important' }:{'margin-top':'80px'}]">
      </div>
      <div class="keyboard d-flex align-center justify-center flex-column " v-if="showKeyboard">
        <v-btn class="key pa-8" @click="toggleCtrl" rounded dark :color="activeToggles.ctrl ? 'primary' : ''">
          Ctrl
        </v-btn>
        <v-btn class="key pa-8" @click="toggleWinKey" rounded dark :color="activeToggles.winKey ? 'primary' : ''">
          <v-icon size="24" color="white">mdi-microsoft-windows</v-icon>
        </v-btn>
        <v-btn class="key pa-8" @click="toggleAlt" rounded dark :color="activeToggles.alt ? 'primary' : ''">
          Alt
        </v-btn>
        <v-btn @click="sendTab" class="key pa-8" rounded dark>
          <v-icon size="24" color="white">mdi-keyboard-tab</v-icon>
        </v-btn>
        <v-btn @click="sendEsc" class="key pa-8" rounded dark>
          <v-icon size="24" color="white">mdi-keyboard-esc</v-icon>
        </v-btn>
      </div>
    </v-container>

  </div>
</template>

<script>
import moment from 'moment';
import {mapGetters, mapActions} from "vuex";
import {hasScrollbarGutter} from "@novnc/novnc/core/util/browser";
import RFB from "@novnc/novnc"
import KeyTable from "@/assets/keysym";

export default {
  computed: {
    ...mapGetters({"currentConnection": "getCurrentConnection", "connectionPassword": "getCurrentConnectionPassword"}),
    connTimeFormatted() {
      if (this.currentConnection.connection_time) {
        return moment().startOf('day').minutes(this.currentConnection.connection_time).format("HH:mm:ss")
      } else {
        return null
      }
    },
    returnRatio() {
    if (this.rfb && this.rfb._fbWidth && this.rfb._fbHeight) {
      return this.rfb._fbWidth / this.rfb._fbHeight;
    }
    return 0;  // or some default value
  }
  },
  data: () => ({
    settings: {
      host: "",
      port: 0,
      encrypt: window.location.protocol === "https:",
      view_clip: false,
      resize: "off",
      quality: 6,
      compression: 2,
      shared: true,
      view_only: false,
      show_dot: false,
      path: "websockify",
      repeaterID: "",
      reconnect: false,
      reconnect_delay: 5000
    },
    disconnectMessage: "",
    timer: null,
    clipboardContent: "",
    clipboardDialog: false,
    connectionEndedDialog: false,
    currentConnectionTime: 0,
    startedAt: "2021-03-09T12:31:22Z",
    connected: false,
    showMobileWarning: false,
    fullscreen: false,
    calibrationDialog: false,
    showKeyboard: false,
    viewDrag: false,
    hideViewDrag: false,
    rfb: null,
    desktopName: "",
    activeToggles: {
      alt: false,
      ctrl: false,
      meta: false,
    }
  }),
  methods: {
    ...mapActions({"updateAlert": "updateAlert", "stopConnection": "stopCurrentConnection"}),
    calculateTime() {
      let startedAt;
      if (this.currentConnection.started_at === "0001-01-01T00:00:00Z") {
        startedAt = moment();
        this.currentConnection.started_at = moment().utc()
      } else {
        startedAt = moment(this.currentConnection.started_at)
      }
      this.currentConnectionTime = moment().diff(startedAt)
      if (this.currentConnectionTime) {
        this.currentConnectionTime = moment.utc(this.currentConnectionTime).format("HH:mm:ss")
      }
    },
    disconnectSession() {
      this.stopConnection().then(() => {
        if (this.connected) {
          // Force kill session since sometimes WebSockify doesn't die on backend
          this.rfb.disconnect();
        }
        clearInterval(this.timer)
      })
    },
    turnCursorOn() {
      let cnvs = document.getElementsByTagName('canvas')
      return cnvs[0].style.cursor = "pointer";
    },
    clipboardReceive(e) {
      this.clipboardContent = e.detail.text;
    },
    clearClipboard() {
      this.clipboardContent = ""
      this.rfb.clipboardPasteFrom("")
    },
    sendClipboard() {
      this.rfb.clipboardPasteFrom(this.clipboardContent)
    },
    isMobile: function () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    closeTab() {
      window.close();
    },
    connectedToServer() {
      this.connected = true;
      let msg;
      if (this.settings.encrypt) {
        msg = `Encrypted connection started with ${this.desktopName}`;
      } else {
        msg = `Unencrypted connection started with ${this.desktopName}`;
      }
      this.updateAlert({
        type: 'success',
        shown: true,
        message: msg,
      });
      this.updateVisualState();
      this.rfb.focus();
    },
    updateVisualState() {
      if (this.connected) {
        this.updateViewClip()
      }
    },
    updateViewClip() {
      if (!this.rfb) return;

      const scaling = this.settings.resize === 'scale';
      if (scaling) {
        this.settings.view_clip = false;
        this.rfb.clipViewport = false;
      } else if (!hasScrollbarGutter) {
        this.settings.view_clip = true;
        this.rfb.clipViewport = true;
      } else {
        this.rfb.clipViewport = this.settings.view_clip;
      }
      this.updateViewDrag()
    },
    updateViewDrag() {
      if (!this.connected) {
        return
      }

      if (!this.rfb.clipViewport && this.rfb.dragViewport) {
        this.rfb.dragViewport = false;
      }

      this.viewDrag = !!this.rfb.dragViewport;

      // this.hideViewDrag = !!this.rfb.clipViewport;
    },
    openFullScreen() {
      if (document.fullscreenElement || // alternative standard method
          document.mozFullScreenElement || // currently working methods
          document.webkitFullscreenElement ||
          document.msFullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (document.body.msRequestFullscreen) {
          document.body.msRequestFullscreen();
        }
      }
      // this.updateFullscreenButton()
    },
    updateFullscreenButton() {
      if (document.fullscreenElement || // alternative standard method
          document.mozFullScreenElement || // currently working methods
          document.webkitFullscreenElement ||
          document.msFullscreenElement) {
        this.fullscreen = true;
      } else {
        this.fullscreen = false;
      }
    },
    sendEsc() {
      this.rfb.sendKey(KeyTable.XK_Escape, "Escape");
      this.rfb.focus();
    },
    sendTab() {
      this.rfb.sendKey(KeyTable.XK_Tab, "Tab")
      this.rfb.focus();
    },
    toggleCtrl() {
      if (this.activeToggles.ctrl) {
        this.rfb.sendKey(KeyTable.XK_Control_L, "ControlLeft", false);
        this.activeToggles.ctrl = false
        this.rfb.focus();
      } else {
        this.rfb.sendKey(KeyTable.XK_Control_L, "ControlLeft", true);
        this.activeToggles.ctrl = true
        this.rfb.focus();
      }
    },
    toggleAlt() {
      if (this.activeToggles.alt) {
        this.rfb.sendKey(KeyTable.XK_Alt_L, "AltLeft", false);
        this.activeToggles.alt = false
        this.rfb.focus();
      } else {
        this.rfb.sendKey(KeyTable.XK_Alt_L, "AltLeft", true);
        this.activeToggles.alt = true
        this.rfb.focus();
      }
    },
    toggleWinKey() {
      if (this.activeToggles.winKey) {
        this.rfb.sendKey(KeyTable.XK_Meta_L, "MetaLeft", false);
        this.activeToggles.winKey = false
        this.rfb.focus();
      } else {
        this.rfb.sendKey(KeyTable.XK_Meta_L, "MetaLeft", true);
        this.activeToggles.winKey = true
        this.rfb.focus();
      }
    },
    openClipboard() {
      this.clipboardDialog = true
    },
    toggleScroll() {
      this.viewDrag = !this.viewDrag;
      this.rfb.viewOnly = !this.rfb.viewOnly;
      if (this.isMobile()) {
        this.rfb.dragViewport = true;
      }
      if (this.viewDrag) {
        this.updateAlert({
          type: 'info',
          shown: true,
          message: 'Input events are disabled while Scroll mode is active.',
        });

      }
    },
    showDeviceIP() {
      this.rfb.sendKey(KeyTable.XK_Control_L, "ControlLeft", true)
      this.rfb.sendKey(KeyTable.XK_i, "i", true)
      this.rfb.sendKey(KeyTable.XK_Control_L, "ControlLeft", false)
      this.rfb.sendKey(KeyTable.XK_i, "i", false)
    },
    showCalibration() {
      this.calibrationDialog = false
      this.rfb.sendKey(KeyTable.XK_Control_L, "ControlLeft", true)
      this.rfb.sendKey(KeyTable.XK_F12, "F12", true)
      this.rfb.sendKey(KeyTable.XK_Control_L, "ControlLeft", false)
      this.rfb.sendKey(KeyTable.XK_F12, "F12", false)
    },
    openSystemConfigurator() {
      this.rfb.sendKey(KeyTable.XK_Control_L, "ControlLeft", true)
      this.rfb.sendKey(KeyTable.XK_F7, "F7", true)
      this.rfb.sendKey(KeyTable.XK_Control_L, "ControlLeft", false)
      this.rfb.sendKey(KeyTable.XK_F7, "F7", false)
    },
    disconnectedFromServer(e) {
      this.connectionEndedDialog = true;
      this.connected = false;
      clearInterval(this.timer);
      if (e.detail.clean) {
        this.disconnectMessage = "Session has ended. You can close this tab now."
      } else {
        this.disconnectMessage = "Something went wrong with the session. You can close this tab now."
      }
    },
    updateDesktopName(e) {
      this.desktopName = e.detail.name;
    },
    credentialsAreRequired() {
      this.rfb.sendCredentials({password: this.connectionPassword});
    },
    initializeFullScreenListeners() {
      window.addEventListener('fullscreenchange', this.updateFullscreenButton);
      window.addEventListener('mozfullscreenchange', this.updateFullscreenButton);
      window.addEventListener('webkitfullscreenchange', this.updateFullscreenButton);
      window.addEventListener('msfullscreenchange', this.updateFullscreenButton);
    }
  },
  mounted() {
    document.documentElement.style.overflowX = 'auto';
    document.addEventListener("keydown", function(event) {
      if (event.ctrlKey && event.altKey && (event.key === "o" || event.key === "O")) {
        alert("jesam te ufatio?")
      }
    })
    this.calculateTime();
    this.timer = setInterval(() => {
      this.calculateTime()
    }, 1000);
    if (this.isMobile()) {
      this.showMobileWarning = true
      this.hideViewDrag = true
    }
    // this.rfb = new RFB(this.$refs.screen, "ws://172.20.15.107:6500/websockify")
    this.rfb = new RFB(this.$refs.screen, `wss://${this.currentConnection.worker}:${this.currentConnection.port + 1000}/websockify`)
    this.rfb.scaleViewport = true;
    if (!this.isMobile()) {
      this.rfb._display.scale = 1
    }
    this.initializeFullScreenListeners()
    this.rfb.addEventListener("connect", this.connectedToServer);
    this.rfb.addEventListener("credentialsrequired", this.credentialsAreRequired);
    this.rfb.addEventListener("disconnect", this.disconnectedFromServer);
    this.rfb.addEventListener("desktopname", this.updateDesktopName);
    this.rfb.addEventListener("clipboard", this.clipboardReceive);
  }
}
</script>

<style scoped lang="scss">
.keyboard {
  position: fixed;
  bottom: 16px;
  left: 16px;
  z-index: 9999;

  .key {
    margin-right: 8px;
    margin-left: 8px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 16px;
    color: white;
    border-radius: 10px;
  }
}

.container {
  margin: 0;
  background-color: rgb(40, 40, 40);
  display: flex;
  flex-direction: column;
}

#screen {
  margin-top: 48px;
  box-sizing: border-box; 
}
.full {
  width: 100% !important;
  height: 99% !important;
}


</style>
